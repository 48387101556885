/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, useEffect } from 'react';
import { styled, css, useTheme, SupersetTheme } from '@superset-ui/core';
import { debounce } from 'lodash';
import { Global } from '@emotion/react';
import { getUrlParam } from 'src/utils/urlUtils';
import { Row, Col, Grid } from 'src/components';
import { MainNav as DropdownMenu, MenuMode } from 'src/components/Menu';
import { Tooltip } from 'src/components/Tooltip';
import { NavLink, useLocation } from 'react-router-dom';
import { GenericLink } from 'src/components/GenericLink/GenericLink';
import Icons from 'src/components/Icons';
import { useUiConfig } from 'src/components/UiConfigContext';
import { URL_PARAMS } from 'src/constants';
import {
  MenuObjectChildProps,
  MenuObjectProps,
  MenuData,
} from 'src/types/bootstrapTypes';
import RightMenu from './RightMenu';
import { isPublicDashboard } from 'src/utils/urlUtils';

interface MenuProps {
  data: MenuData;
  isFrontendRoute?: (path?: string) => boolean;
}
/*...............DRONAHQ-CHANGES................ */
const StyledHeader = styled.header`
  ${({ theme }) => `
      background-color: ${theme.colors.grayscale.light5};
      margin-bottom: 1px;
      z-index: 10;
      height: 40px;

       > .ant-row {
        border-bottom: 1px solid #ccc;
      }

      &:nth-last-of-type(2) nav {
        margin-bottom: 2px;
      }
      .caret {
        display: none;
      }
      .navbar-brand {
        display: flex;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #ddd;
        /* must be exactly the height of the Antd navbar */
        padding:0px 16px;
        img {
          height: 18px;
          object-fit: contain;
        }
      }
      .navbar-brand-text {
        border-left: 1px solid ${theme.colors.grayscale.light2};
        border-right: 1px solid ${theme.colors.grayscale.light2};
        height: 100%;
        color: ${theme.colors.grayscale.dark1};
        padding-left: ${theme.gridUnit * 4}px;
        padding-right: ${theme.gridUnit * 4}px;
        margin-right: ${theme.gridUnit * 6}px;
        font-size: ${theme.gridUnit * 4}px;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          max-width: ${theme.gridUnit * 58}px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (max-width: 1127px) {
          display: none;
        }
      }
      .main-nav .ant-menu-submenu-title > svg {
        top: ${theme.gridUnit * 5.25}px;
      }
      @media (max-width: 767px) {
        .navbar-brand {
          float: none;
        }
      }
      .ant-menu-horizontal .ant-menu-item {
        height: 100%;
        line-height: inherit;
        font-weight:500;
      }
      .ant-menu > .ant-menu-item > a {
        padding: ${theme.gridUnit * 4}px;
      }
      @media (max-width: 767px) {
        .ant-menu-item {
          padding: 0 ${theme.gridUnit * 6}px 0
            ${theme.gridUnit * 3}px !important;
        }
        .ant-menu > .ant-menu-item > a {
          padding: 0px;
        }
        .main-nav .ant-menu-submenu-title > svg:nth-of-type(1) {
          display: none;
        }
        .ant-menu-item-active > a {
          &:hover {
            color: ${theme.colors.primary.base} !important;
            background-color: transparent !important;
          }
        }
      }
      .ant-menu-item a {
        &:hover {
          color: ${theme.colors.grayscale.dark1};
          background-color: ${theme.colors.primary.light5};
          border-bottom: none;
          margin: 0;
          &:after {
            opacity: 1;
            width: 100%;
          }
        }
      }
  `}
`;
const globalStyles = (theme: SupersetTheme) => css`
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft {
    border-radius: 0px;
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light {
    border-radius: 0px;
  }
  .ant-menu-vertical > .ant-menu-submenu.data-menu > .ant-menu-submenu-title {
    height: 28px;
    i {
      padding-right: ${theme.gridUnit * 2}px;
      margin-left: ${theme.gridUnit * 1.75}px;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent;
    &:not(.ant-menu-item-active) {
      color: inherit;
      border-bottom-color: transparent;
      & > a {
        color: inherit;
      }
    }
  }


  /* .............................DRONAHQSTYLECSS-STARTED.....................................*/


  .ant-select-selector:hover , .ant-input-affix-wrapper:hover {
    border-color:${theme.colors.secondary.dark1} !important;
    border-right-width: 1px !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #26bc6a !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: inherit !important;
    font-weight: 600;
    background-color: #f7f6f6 !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused, .ant-picker-focused {
    border-color:${theme.colors.secondary.dark1} !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }

  .action-button .anticon {
    font-size: ${theme.gridUnit * 5}px !important;
  }
  .ant-input:hover{
    border-color:${theme.colors.secondary.dark1} !important;
    border-right-width: 1px !important;
    box-shadow: none !important;
  }
  .ant-input:focus, .ant-input-focused , .form-control:focus{
    border-color:${theme.colors.secondary.dark1} !important;
    box-shadow: none !important;
  }
  .ant-tabs-tab-btn, .ant-tabs{
    font-size: ${theme.gridUnit * 3}px !important;
    font-weight: ${theme.typography.weights.bold};
    color: ${theme.colors.secondary.dark2};
    // text-transform: capitalize;
  }
  .ant-tabs-tab:hover {
    color: ${theme.colors.primary.base};
  }
  .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: ${theme.colors.primary.base};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn , .text-primary, .text-primary:hover  {
    color: ${theme.colors.primary.base} !important;
  }
  .ant-modal-header {
    padding: 12px 24px !important;
    border-bottom: 1px solid #ccc !important;
    background: unset !important;
  }
  .ant-modal-footer {
    padding: 8px 16px !important;
    border-top: 1px solid #ccc !important;
  }
  .ant-form legend {
    font-size: ${theme.gridUnit * 3}px !important;
    color: ${theme.colors.grayscale.dark1};
    font-weight:${theme.typography.weights.medium};
  }
  .ant-form label ,  .control-label, .ant-radio-wrapper{
    font-size: ${theme.gridUnit * 3}px!important;
    color: #3f5662 !important;
    font-weight:${theme.typography.weights.medium};
    text-transform: capitalize;
  }
  .help-block{
    font-size: calc(${theme.gridUnit} * 3px - 1px) !important;
    color: ${theme.colors.grayscale.dark1};
  }
  .ant-input{
    font-size: ${theme.gridUnit * 3}px !important
  }
  .ant-select-selection-placeholder , .ant-select {
    font-size: ${theme.gridUnit * 3}px !important;
  }
  .ant-select-item,
  .ant-tabs-dropdown-menu-item{
    font-size: ${theme.gridUnit * 3}px !important;
    color: ${theme.colors.grayscale.dark1};
  }
  .ant-form-item-explain, .ant-form-item-extra {
    font-size: calc(${theme.gridUnit} * 3px - 1px) !important;
    color: #707070;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
      color: ${theme.colors.primary.base} !important; 
      border-bottom: 2px solid ${theme.colors.primary.base} !important;
  }
  .select-db h4 {
   color: ${theme.colors.grayscale.dark1} !important;
   font-size: ${theme.gridUnit * 3.5}px!important;
  }
  .ant-modal-close-x{
    height: 46px !important;
    width: 46px !important;
  }
  .panel-primary .panel-title{
    font-size: ${theme.gridUnit * 3.5}px !important;
    color: ${theme.colors.grayscale.dark1};
     font-weight: ${theme.typography.weights.bold} !important;
  }
  .ant-menu-item.ant-menu-item-only-child:hover {
    background-color: #f5f5f5 !important;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color:  ${theme.colors.grayscale.dark1} !important;
    font-weight: ${theme.typography.weights.medium} !important;
    font-size: ${theme.gridUnit * 3}px !important;
    line-height: ${theme.gridUnit * 5.5}px !important;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background: #f7f7f7 !important;
    border-bottom: 1px solid #ddd;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected , .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover{
    background-color: #f0f0f0 !important;
    color: #000;
  }
  .table > thead > tr > th {
    background-color: #f7f7f7;
    font-size: 12px;
    font-weight: 500;
    color: #3f5662;
    text-transform: capitalize;
  }
  .table > tbody > tr > td
  .ant-collapse-content.ant-collapse-content-active {
    font-size: calc(${theme.gridUnit} * 3px - 1px) !important;
    line-height: 1.9 !important;
  }
  .ant-collapse-item .ant-collapse-header{
    font-weight:${theme.typography.weights.medium} !important;
  }
  .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
   font-size: calc(${theme.gridUnit} * 2.5px) !important;
  }

  /* .............................DRONAHQSTYLECSS-ENDED....................................*/


  .ant-menu-horizontal > .ant-menu-item:has(> .is-active) {
    color: ${theme.colors.primary.base};
    border-bottom-color: ${theme.colors.primary.base};
    & > a {
      color: ${theme.colors.primary.base};
    }
  }
  .ant-menu-vertical > .ant-menu-item:has(> .is-active) {
    background-color: ${theme.colors.primary.light5};
    & > a {
      color: ${theme.colors.primary.base};
    }
  }
    

  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-title {
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-title .fa.fa-plus[data-test="new-dropdown-icon"] {
    color: #26bc6a; 
    font-size: 18px;
    height: auto; 
    padding:0 !important;
    position: relative; 
    top: 8px; 
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-title span.anticon.anticon-setting {
    position: relative;
    top: 7px !important;
    height: auto !important; 
    padding: 0 !important;
    right: -3px;
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light .css-1v87icz-RightMenu > .ant-menu-submenu-title span[role='img'] svg {
  font-size: 18px;
 }
 .ant-menu-submenu.ant-menu-submenu-vertical-left.data-menu .ant-menu-submenu-title {
    display: flow;
    height: 32px !important;
    margin-top: 8px !important;
    margin-bottom: 4px;
    padding: 0 16px !important;
    overflow: hidden;
    line-height: 42px;
    text-overflow: ellipsis;
}
 .ant-menu-submenu.ant-menu-submenu-vertical.data-menu .ant-menu-submenu-title {
    display: flow;
    height: 32px !important;
    margin-top: 8px !important;
    margin-bottom: 4px;
    padding: 0 16px !important;
    overflow: hidden;
    line-height: 42px;
    text-overflow: ellipsis;
}
.ant-menu-submenu.ant-menu-submenu-vertical-left.data-menu .ant-menu-submenu-title i.fa.fa-database {
  text-align: center;
  width: 1.60em;
}
`;
const { SubMenu } = DropdownMenu;

const { useBreakpoint } = Grid;

export function Menu({
  data: {
    menu,
    brand,
    navbar_right: navbarRight,
    settings,
    environment_tag: environmentTag,
  },
  isFrontendRoute = () => false,
}: MenuProps) {
  const [showMenu, setMenu] = useState<MenuMode>('horizontal');
  const screens = useBreakpoint();
  const uiConfig = useUiConfig();
  const theme = useTheme();

  console.log("Hello....,menu data..",settings, menu);

  let databaseConn:any = null;
  if(settings.length > 1) {
    if(settings && settings[1] && settings[1]["childs"]) {
      databaseConn = settings[1]["childs"][0];
    }
    
  }
  else if(settings.length == 1) {
    if(settings && settings[0] && settings[0]["childs"]) {
      databaseConn = settings[0]["childs"][0];
    }
  }

  const [currentMenu, setCurrentMenu] = useState(menu);
  useEffect(() => {
    let newMenu = [...menu];
    let isExists = newMenu.filter((obj) => obj.name === "Databases");
    if (isExists.length === 0 && databaseConn && !newMenu.some(item => item.name === databaseConn.name)) {
      newMenu.push(databaseConn);
    }
    setCurrentMenu(newMenu);
  }, [menu, databaseConn]);

  useEffect(() => {
    console.log("Current menu updated:", currentMenu);
  }, [currentMenu]);


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 767) {
        setMenu('inline');
      } else setMenu('horizontal');
    }
    handleResize();
    const windowResize = debounce(() => handleResize(), 10);
    window.addEventListener('resize', windowResize);
    return () => window.removeEventListener('resize', windowResize);
  }, []);

  enum Paths {
    Explore = '/explore',
    Dashboard = '/dashboard',
    Chart = '/chart',
    Datasets = '/tablemodelview',
  }

  const defaultTabSelection: string[] = [];
  const [activeTabs, setActiveTabs] = useState(defaultTabSelection);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    switch (true) {
      case path.startsWith(Paths.Dashboard):
        setActiveTabs(['Dashboards']);
        break;
      case path.startsWith(Paths.Chart) || path.startsWith(Paths.Explore):
        setActiveTabs(['Charts']);
        break;
      case path.startsWith(Paths.Datasets):
        setActiveTabs(['Datasets']);
        break;
      default:
        setActiveTabs(defaultTabSelection);
    }
  }, [location.pathname]);

  const standalone = getUrlParam(URL_PARAMS.standalone);
  /* HIDE HEADER IF IT IS PUBLIC LINK */
  const isPublicDashboardLink = isPublicDashboard();

  if (standalone || uiConfig.hideNav || isPublicDashboardLink) return <></>;

  const renderSubMenu = ({
    label,
    childs,
    url,
    index,
    isFrontendRoute,
  }: MenuObjectProps) => {
    if (url && isFrontendRoute) {
      return (
        <DropdownMenu.Item key={label} role="presentation">
          <NavLink role="button" to={url} activeClassName="is-active">
            {label}
          </NavLink>
        </DropdownMenu.Item>
      );
    }
    if (url) {
      return (
        <DropdownMenu.Item key={label}>
          <a href={url}>{label}</a>
        </DropdownMenu.Item>
      );
    }
    return (
      <SubMenu
        key={index}
        title={label}
        icon={showMenu === 'inline' ? <></> : <Icons.TriangleDown />}
      >
        {childs?.map((child: MenuObjectChildProps | string, index1: number) => {
          if (typeof child === 'string' && child === '-' && label !== 'Data') {
            return <DropdownMenu.Divider key={`$${index1}`} />;
          }
          if (typeof child !== 'string') {
            return (
              <DropdownMenu.Item key={`${child.label}`}>
                {child.isFrontendRoute ? (
                  <NavLink
                    to={child.url || ''}
                    exact
                    activeClassName="is-active"
                  >
                    {child.label}
                  </NavLink>
                ) : (
                  <a href={child.url}>{child.label}</a>
                )}
              </DropdownMenu.Item>
            );
          }
          return null;
        })}
      </SubMenu>
    );
  };
  return (
    <StyledHeader className="top" id="main-menu" role="navigation">
      <Global styles={globalStyles(theme)} />
      <Row>
        <Col md={16} xs={24}>
          <Tooltip
            id="brand-tooltip"
            placement="bottomLeft"
            title={brand.tooltip}
            arrowPointAtCenter
          >
            {isFrontendRoute(window.location.pathname) ? (
              <GenericLink className="navbar-brand" to={brand.path}>
                <img src={brand.icon} alt={brand.alt} />
              </GenericLink>
            ) : (
              <a className="navbar-brand" href={brand.path}>
                <img src={brand.icon} alt={brand.alt} />
              </a>
            )}
          </Tooltip>
          {brand.text && (
            <div className="navbar-brand-text">
              <span>{brand.text}</span>
            </div>
          )}
          {!navbarRight.user_is_anonymous && (
          <DropdownMenu
            mode={showMenu}
            data-test="navbar-top"
            className="main-nav"
            selectedKeys={activeTabs}
          >
            {currentMenu.map((item, index) => {
              const props = {
                index,
                ...item,
                isFrontendRoute: isFrontendRoute(item.url),
                childs: item.childs?.map(c => {
                  if (typeof c === 'string') {
                    return c;
                  }

                  return {
                    ...c,
                    isFrontendRoute: isFrontendRoute(c.url),
                  };
                }),
              };

              return renderSubMenu(props);
            })}
          </DropdownMenu>
         )}
        </Col>
        <Col md={8} xs={24}>
          <RightMenu
            align={screens.md ? 'flex-end' : 'flex-start'}
            settings={settings}
            navbarRight={navbarRight}
            isFrontendRoute={isFrontendRoute}
            environmentTag={environmentTag}
          />
        </Col>
      </Row>
    </StyledHeader>
  );
}

// transform the menu data to reorganize components
export default function MenuWrapper({ data, ...rest }: MenuProps) {
  const newMenuData = {
    ...data,
  };
  // Menu items that should go into settings dropdown
  const settingsMenus = {
    Data: true,
    Security: true,
    Manage: true,
  };

  // Cycle through menu.menu to build out cleanedMenu and settings
  const cleanedMenu: MenuObjectProps[] = [];
  const settings: MenuObjectProps[] = [];
  newMenuData.menu.forEach((item: any) => {
    if (!item) {
      return;
    }

    const children: (MenuObjectProps | string)[] = [];
    const newItem = {
      ...item,
    };

    // Filter childs
    if (item.childs) {
      item.childs.forEach((child: MenuObjectChildProps | string) => {
        if (typeof child === 'string') {
          children.push(child);
        } else if ((child as MenuObjectChildProps).label) {
          children.push(child);
        }
      });

      newItem.childs = children;
    }

    if (!settingsMenus.hasOwnProperty(item.name)) {
      cleanedMenu.push(newItem);
    } else {
      settings.push(newItem);
    }
  });

  newMenuData.menu = cleanedMenu;
  newMenuData.settings = settings;

  return <Menu data={newMenuData} {...rest} />;
}
